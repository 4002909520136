<template>
<!-- main -->
<main id="container" class="newScrollAction" >
    <h1 class="screen_out">CEO 클리닉</h1>

    <!-- section 위치표시 및 바로가기 -->
    <nav id="nav_container">
        <div class="list_nav">
            <a class="link_nav" data-target="#visual">메인</a>
            <a class="link_nav" data-target="#business">사업단<span class="hide"> 현황</span></a>
            <a class="link_nav" data-target="#network">전문가<span class="hide"> 네트워크</span></a>
            <a class="link_nav" data-target="#roadMap">로드맵</a>
            <a class="link_nav" data-target="#solution"><span class="hide">리스크 </span>솔루션</a>
            <!-- <a class="link_nav" data-target="#worry"><span class="hide">27가지 </span>고민해결</a> -->
            <a class="link_nav" data-target="#start">시작</a>
            <a class="link_nav" data-target="#freeConsult"><span class="hide">성공의 </span>비전제시</a>
            <a class="link_nav" data-target="#ci">CI<span class="hide"> 소개</span></a>
        </div>
    </nav>
    <!-- //section 위치표시 및 바로가기 -->

    <!-- 메인 비주얼 -->
    <section id="visual" class="section" style="background-image:url(/img/brand/ceo/visual_bg.jpg)">
        <h3 class="tit_section">지속가능경영을 위한<strong>리스크 관리 컨설팅<br>CEO클리닉</strong></h3>
    </section>
    <!-- //메인 비주얼 -->


    <section id="is_Ceo">
        <div class="inner">
            <h3 class="tit_section"><strong>CEO클리닉</strong>은,</h3>
            <p> 
                법인컨설팅의 명가 피플라이프의 <strong>중소ㆍ<br>
                중견기업을 위한 경영효율화 전문 브랜드</strong>로써 <br>
                기업과 오너CEO의 RISK관리, 가업승계, <br>
                증여와 상속에 이르기까지 세무, 법무, 노무, M&A, 부동산 <br>
                특화된 전문가 네트워크와 협업하여 <strong>지속가능경영을 <br>
                위한 가장 효율적인 솔루션 제공</strong>
                과 실행을 제안합니다.
			</p>
			<div class="label"><span>특히 기업과 주주, 임원들의 동반 성장과</span></div>
			<div class="label"><span>안정을 위한 최적의 전략을 통해</span></div>
			<div class="label"><span>명문가 사업 및 가업을 육성 합니다.</span></div>
        </div>
    </section>
    

    <!-- 사업단 -->
    <section id="business" class="section">
        <div class="inner">
            <h3 class="tit_section">전국 법인컨설팅 솔루션<br><strong>「CEO CLINC」</strong></h3>
            <p class="desc_section">
                피플라이프의 대표 법인컨설팅 브랜드 'CEO클리닉'은 제휴된 여러 전문가 네트워크와 함께<br>
                <mark>'중소기업 경영효율화 법인컨설팅'</mark> 이라는<br>
                새로운 분야를 개척함으로써 각종 경영 리스크관리 및<br>
                경영 효율화 전략을 실행 제안합니다.
            </p>
            <div class="map">
                <dl class="list_map seoul pos_left">
                    <dt>
                        <em>서울</em>
                        <!-- <strong>46</strong> -->
                    </dt>
                </dl>
                <dl class="list_map kangwon pos_right">
                    <dt>
                        <em>강원</em>
                        <!-- <strong>4</strong> -->
                    </dt>
                    <!-- <dd><em>원주</em><strong>2</strong></dd> -->
                    <!-- <dd><em>춘천</em><strong>1</strong></dd> -->
                </dl>
                <dl class="list_map  gyeonggi pos_right">
                    <dt>
                        <em>경기</em>
                        <!-- <strong>11</strong> -->
                    </dt>
                    <!-- <dd><em>인천</em><strong>1</strong></dd> -->
                    <!-- <dd><em>일산</em><strong>1</strong></dd> -->
                    <!-- <dd><em>성남</em><strong>1</strong></dd> -->
                    <!-- <dd><em>안양</em><strong>1</strong></dd> -->
                    <!-- <dd><em>용인</em><strong>4</strong></dd> -->
                </dl>

                <dl class="list_map chungchung pos_left">
                    <dt>
                        <em>충청</em>
                        <!-- <strong>4</strong> -->
                    </dt>
                    <!-- <dd><em>충주</em><strong>1</strong></dd> -->
                    <!-- <dd><em>제천</em><strong>1</strong></dd> -->
                    <!-- <dd><em>대전</em><strong>3</strong></dd> -->
                </dl>
                <dl class="list_map jeolla pos_left">
                    <dt>
                        <em>전라</em>
                        <!-- <strong>5</strong> -->
                    </dt>
                    <!-- <dd><em>광주</em><strong>3</strong></dd> -->
                </dl>
                <dl class="list_map gyeongsang pos_right">
                    <dt>
                        <em>경상</em>
                        <!-- <strong>18</strong> -->
                    </dt>
                    <!-- <dd><em>구미</em><strong>1</strong></dd> -->
                    <!-- <dd><em>대구</em><strong>6</strong></dd> -->
                    <!-- <dd><em>울산</em><strong>2</strong></dd> -->
                    <!-- <dd><em>부산</em><strong>7</strong></dd> -->
                    <!-- <dd><em>해운대</em><strong>1</strong></dd> -->
                </dl>
                <dl class="list_map jeju pos_left">
                    <dt>
                        <em>제주</em>
                        <!-- <strong>1</strong> -->
                    </dt>
                </dl>
                <img class="bg" :src="imgPath+'business_map_bg.png?v=1'" alt="">
                <img class="bg_line" :src="imgPath+'business_map_bg_line_v1.png?v=2'" alt="">
            </div>
            <ul class="list_count">
                <!-- <li class="item_count">
                    <p class="tit"><img :src="imgPath+'business_ico_building.png'" alt="">CEO클리닉<strong>사업단 현황</strong></p>
                    <p class="num"><span>89</span>개</p>
                    <p class="source">2024년 5월 사업단 현황</p>
                </li> -->
                <!-- <li class="item_count">
                    <p class="tit"><img :src="imgPath+'business_ico_person.png'" alt="">CEO클리닉<strong>재무보좌관 현황</strong></p>
                    <p class="num"><span>1,460</span>명</p>
                    <p class="source">2022년 6월 설계사수 현황</p>
                </li> -->
            </ul>
        </div>
    </section>
    <!-- //사업단 -->

    <!-- 전문가 -->
    <section id="network" class="section">
        <h3 class="tit_section">전문가와 최적의 솔루션을 제시</h3>
        <p class="desc_section">중소기업을 경영할 때에는<br>여러 가지 위험요소가 산재합니다.<br>CEO클리닉은 분야별 전문가 그룹과 제휴를 맺어<br><mark> 자산관리 및 경영 지원에 필요한 모든 프로세스를</mark><br><mark>효율적으로 지원</mark>  하고 있습니다.</p>
        <a class="link_more" href="/intro/network" target="_blank"><span>전문가 네트워크 자세히 보기</span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="다음"></a>
        <ul class="list_network isAppear70">
            <li class="item_network" data-name="1">
                <p class="tit">세무</p>
                <p class="desc">세무법인 <br> 세종TSI</p>
                <img src="/img/brand/ceo/1.png" alt="프로필">
            </li>
            <li class="item_network" data-name="2">
                <div class="left">
                    <p class="tit">법무</p>
                    <p class="desc">가이우스<br>법률사무소</p>
                </div>
                <div class="right">
                    <p class="tit">노무</p>
                    <p class="desc">노무법인<br>서현</p>
                </div>
                <img src="/img/brand/ceo/2.png" alt="프로필">
            </li>
            <li class="item_network" data-name="3">
                <p class="tit">노무</p>
                <p class="desc">노무법인<br>익선</p>
                <img src="/img/brand/ceo/3.png" alt="프로필">
            </li>
            <li class="item_network" data-name="4">
                <p class="tit">M&A</p>
                <p class="desc">한국M&A<br>거래소</p>
                <img src="/img/brand/ceo/5.png" alt="프로필">
            </li>
            <li class="item_network" data-name="5">
                <p class="tit">기업금융</p>
                <p class="desc">IPO·투자유치<br>·M&A자문 </p>
                <img src="/img/brand/ceo/6.png" alt="프로필">
            </li>
            <li class="item_network" data-name="6">
                <p class="tit">부동산</p>
                <p class="desc">부동산<br>중개</p>
                <img src="/img/brand/ceo/7.png?v=231010" alt="프로필">
            </li>
            <!-- <li class="item_network" data-name="7">
                <p class="tit">제휴</p>
                <p class="desc">감정평가법인<br>대일감정원</p>
                <img src="/img/brand/ceo/8.png" alt="프로필">
            </li>
            <li class="item_network" data-name="8">
                <p class="tit">제휴</p>
                <p class="desc">에이스<br> 손해사정평가 </p>
                <img src="/img/brand/ceo/9.png" alt="프로필">
            </li>
            <li class="item_network" data-name="9">
                <p class="tit">제휴</p>
                <p class="desc">청일국제특허<br> 법률사무소</p>
                <img src="/img/brand/ceo/10.png" alt="프로필">
            </li> -->
            <li class="item_network" data-name="7">
                <p class="tit">경정청구</p>
                <p class="desc">DPBS</p>
                <img src="/img/brand/ceo/11.png" alt="프로필">
            </li>
        </ul>
    </section>
    <!-- //전문가 -->

    <!-- 컨설팅 흐름 -->
    <roadMap />
    <!-- //컨설팅 흐름 -->

    <!-- 솔루션 -->
    <section id="solution" class="section dataTarget" data-scroll-cut-line="10">
        <h3 class="tit_section">CEO클리닉은 7가지<br>‘<strong>RISK Solution</strong>’을  제공합니다.</h3>
        <p class="desc_section">중소기업 위기 극복 파트너 CEO 클리닉은<br> 기업 경영의 장애물과 리스크를 최소화하고<br> CEO, 종업원, 회사 전반에 필요한<br>경영효율화 컨설팅을 제공하고 있습니다.</p>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <p class="tit">경영리스크<br>관리</p>
                    <p class="desc">명의신탁주식,<br> 가지급금,<br> 임원 사망 대비 등</p>
                </div>
                <div class="swiper-slide">
                    <p class="tit">재무분석</p>
                    <p class="desc">기업 검진,<br> 정관 정비,<br> 재무구조 분석</p>
                </div>
                <div class="swiper-slide">
                    <p class="tit">CEO<br> 자산관리</p>
                    <p class="desc">합법적 · 체계적<br> 상속세 · 증여세 · <br> 법인세 절세</p>
                </div>
                <div class="swiper-slide">
                    <p class="tit">CEO<br> 가업승계 </p>
                    <p class="desc">100년 기업<br> 도약을 위한<br> 가업승계 플랜</p>
                </div>
                <div class="swiper-slide">
                    <p class="tit">금융컨설팅</p>
                    <p class="desc">재무구조<br> 개선을 통한<br> IPO, M&A</p>
                </div>
                <div class="swiper-slide">
                    <p class="tit">운용자금<br> 플래닝</p>
                    <p class="desc">사업 확장을 위한<br> 장 · 단기 운용자금<br> 플래닝</p>
                </div>
                <div class="swiper-slide">
                    <p class="tit">경영효율화<br> 전략</p>
                    <p class="desc">인사노무시스템 제안,<br> 각종 인증 제도 및<br> 부설연구소</p>
                </div>
            </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
    </section>
    <!-- //솔루션 -->

    <!-- 고민해결 -->
    <section id="worry" class="section hidden">
        <div class="inner">
            <div class="swiper-container">
                <p class="tit_container">CEO클리닉에서는<i></i>고민을 해결해드립니다.</p>
                <div class="swiper-wrapper">
                    <div class="swiper-slide"></div>
                </div>
            </div>
            <div class="list_video">
                <button class="videoLayer" data-src="https://www.youtube.com/embed/KKc9rJo107k" data-title="" data-type="youtube" type="button" title="영상재생">
                    <img class="thumb" src="https://i.ytimg.com/vi/KKc9rJo107k/maxresdefault.jpg" alt="스크린샷">
                    <p class="tit lineClamp2">1. 법인의 이익금 환원! 쉽게 설명해주는 세금절약 가이드</p>
                </button>
                <button class="videoLayer" data-src="https://www.youtube.com/embed/DB8JuNeoqfo" data-title="" data-type="youtube" type="button" title="영상재생">
                    <img class="thumb" src="https://i.ytimg.com/vi/DB8JuNeoqfo/maxresdefault.jpg" alt="스크린샷">
                    <p class="tit lineClamp2">2. 가족 주주회사의 선택은, 절세의 블랙홀</p>
                </button>
                <button class="videoLayer" data-src="https://www.youtube.com/embed/AOEW-5xl-gM" data-title="" data-type="youtube" type="button" title="영상재생">
                    <img class="thumb" src="https://i.ytimg.com/vi/AOEW-5xl-gM/maxresdefault.jpg" alt="스크린샷">
                    <p class="tit lineClamp2">3. 사업 좀 된다 싶으면 '성실신고확인' 대상 피하는 방법!</p>
                </button>
                <button class="videoLayer" data-src="https://www.youtube.com/embed/SA5KiZmLjNk" data-title="" data-type="youtube" type="button" title="영상재생">
                    <img class="thumb" src="https://i.ytimg.com/vi/SA5KiZmLjNk/maxresdefault.jpg" alt="스크린샷">
                    <p class="tit lineClamp2">4. 고용지원 중 권고사직과 해고문제</p>
                </button>
                <button class="videoLayer" data-src="https://www.youtube.com/embed/COhWVkeDZA4" data-title="" data-type="youtube" type="button" title="영상재생">
                    <img class="thumb" src="https://i.ytimg.com/vi/COhWVkeDZA4/maxresdefault.jpg" alt="스크린샷">
                    <p class="tit lineClamp2">5. 중소기업 정관에 꼭 들어가야 할 내용의 모든 것</p>
                </button>
            </div>
            <a class="link_more" href="https://www.youtube.com/channel/UCv127K0IeyDsPttFEw20blQ" target="_blank"><img class="ico_youtube" :src="imgPath+'ico_youtube.png'" alt="youtube"><span>채널 바로가기</span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="채널 바로가기"></a>
        </div>
    </section>
    <!-- //고민해결 -->

    <!-- 시작  -->
    <section id="start" class="section">
        <p class="sub_title">기업경영 시 <strong>코칭</strong>이 필요할 때!</p>
        <p class="tit_section">CEO클리닉은 언제나 고객의 자산 가치를<br>높이기 위해 노력하고 있습니다.</p>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" :style="'background-image:url('+imgPath+'start_bg1.jpg)'">
                    <p class="txt">급변하는 세상과 유행에 빠르게 변화하는<br>경제환경에 우리는 생각했습니다.<br>은퇴 후에도 최소 30년은 살아야 하는 초고령 사회에서<br> <mark>자산을 어떻게 효율적으로 관리해야 할지,</mark><br><mark>적절한 솔루션을 제시해 준다면 얼마나 든든할까요?</mark></p>
                </div>
                <div class="swiper-slide" :style="'background-image:url('+imgPath+'start_bg2.jpg)'">
                    <p class="txt">급변하는 경제 상황 속에서<br>가장 필요한 ‘최적의 솔루션’<br><mark>우리는 기업과 개인 고객의 안정적인 성장을 위해</mark><br><mark>다양한 분야의 전문가와 함께 최적의 솔루션을</mark><br><mark>제공하기 위해 만들어졌습니다.</mark></p>
                </div>
                <div class="swiper-slide" :style="'background-image:url('+imgPath+'start_bg3.jpg)'">
                    <p class="txt">CEO클리닉은 십수 년간 경험한 노하우로<br><mark>늘 기업과 함께 도약하는 경영 컨설팅 브랜드</mark>입니다.</p>
                </div>
            </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
    </section>
    <!-- //시작  -->

    <!-- 브로슈어 -->
    <section class="brochure">
        <h3 class="design_title"><img class="bi" src="" data-images-path="/image/logo/ceoclinic_extension.svg" alt="로고"> Brochure <span>중소기업을 위한<br><strong>경영효율화 컨설팅</strong></span></h3>
        <p class="txt">기업경영시 코칭이 필요한 이유를 지금 확인해보세요</p>
        <div class="img"><img :src="imgPath+'brochure_book.png?v=2401101'" alt="brochure"></div>
        <a class="button" href="" data-json-path="/pdf/brochure2024.pdf" target="_blank">브로슈어 보기</a>
    </section>
    <!-- //브로슈어 -->

    <!-- 상담신청  -->
    <section id="freeConsult" class="section">
        <h3 class="tit_section">컨설팅 신청</h3>
        <div class="tab-container">
            <nav class="tab-nav">
                <button data-tab-target="#tab-corp" type=button><img src="/img/common/ico/ico_building01.svg" alt=""><span>법인컨설팅</span></button>
                <button data-tab-target="#tab-indi" type=button><img src="/img/common/ico/ico_person01.svg" alt=""><span>개인컨설팅</span></button>
            </nav>
            <div class="tab-wrapper">
                <div id="tab-corp" class="tab-slide">
                    <div class="infographic">
                        <div class="txt_area">
                            <p class="tit">임·직원</p>
                            <p class="desc">단체보장플랜, 사업장 재물보험</p>
                        </div>
                        <div class="txt_area">
                            <p class="tit">회사(사업지원)</p>
                            <p class="desc">특허·지적재산권, 부설연구소,<br>인증 ·각종제도정비</p>
                        </div>
                        <div class="txt_area">
                            <p class="tit">CEO</p>
                            <p class="desc">가업 승계플랜, 증여와 상속,<br>기업 지배구조개선</p>
                        </div>
                        <div class="txt_area">
                            <p class="tit">회사(재무지원)</p>
                            <p class="desc">차명주식환원, 가지급금 정리,<br>주식이동·배당, 자기주식취득,<br>이익잉여금 정리</p>
                        </div>
                        <img class="bg" :src="imgPath+'freeConsult_infographic_bg1.png'" alt="">
                    </div>
                    <div class="form_wrap">
                        <h3 class="tit_form">법인컨설팅</h3>
                        <div class="render_form"></div>
                    </div>
                    <li class="row row_type row_css clearfix" data-type="type" data-check="false" data-check-pass="false" data-check-comment="상담분야를 선택해주세요" data-inbound-prop="codeId">
                        <label class="select_wrap">
                            <select name="consult" class="openSans consult select_css">
                                <option value="">상담분야 선택</option>
                                    <option value="219">가지급금</option>
                                    <option value="220">명의신탁(차명주식)</option>
                                    <option value="221">법인설립/전환</option>
                                    <option value="222">자기주식</option>
                                    <option value="223">가업승계</option>
                                    <option value="224">기업제도 정비</option>
                                    <option value="225">이익잉여금 환원</option>
                                    <option value="226">배당</option>
                                    <option value="227">비상장주식이동</option>
                                    <option value="228">증여</option>
                                    <option value="229">기타</option>
                            </select>
                            <u class="select_arrow arrow_css"></u>
                        </label>
                    </li>
                </div>
                <div id="tab-indi" class="tab-slide">
                    <div class="infographic">
                        <div class="txt_area">
                            <p class="tit">개인 보유 자산의 진단 및 분석</p>
                        </div>
                        <div class="txt_area">
                            <p class="tit">목적자금 플래닝</p>
                            <p class="desc">결혼, 교육, 주택 마련, 목돈 마련 외</p>
                        </div>
                        <div class="txt_area">
                            <p class="tit">개인 사업자 법인전환 컨설팅</p>
                        </div>
                        <div class="txt_area">
                            <p class="tit">부동산 자산 토탈 컨설팅</p>
                            <p class="desc">효율적 사전증여, 임대사업자<br>법인전환, 리모델링, 임대사업 극대화</p>
                        </div>
                        <div class="txt_area">
                            <p class="tit">은퇴설계</p>
                            <p class="desc">저금리시대 대비 투자를 통한<br>노후 준비, 보장자산 마련</p>
                        </div>
                        <img class="bg" :src="imgPath+'freeConsult_infographic_bg2.png'" alt="">
                    </div>
                    <div class="form_wrap">
                        <h3 class="tit_form">개인컨설팅</h3>
                        <div class="render_form"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //상담신청  -->

    <!-- CI -->
    <section id="ci" class="section">
        <div class="inner">
            <h3 class="tit_section">CEO클리닉 <strong>BI소개</strong></h3>
            <div class="download">
                <div class="box_logo">
                    <img src="" data-images-path="/image/logo/ceoclinic_basic.svg" alt="로고">
                </div>
                <p class="txt">CEO클리닉은 안정된 기반 위에 올라서는 CEO의 모습을 형상화 하였습니다.<br><br> 기업의 성공을 위한 전방위적인 솔루션 지원과 성공을 위한 로드맵을 제시하겠다는 메시지를 전달하고 있습니다.<br><br> 전체적으로 신뢰감을 주고 성공을 떠올릴 수 있는 이미지로 심플하고 임팩트 있게 표현하였습니다</p>
            </div>
            <div class="system isAppear">
                <p class="tit_system">Color System</p>
                <ul class="list_system">
                    <li class="item_system">
                        <p class="tit">CC_BLUE</p>
                        <p class="cmyk"><strong>CMYK</strong>C100 / M26 / Y0 / K0</p>
                        <p class="rgb"><strong>RGB</strong>R0 / G142 / B211</p>
                    </li>
                    <li class="item_system">
                        <p class="tit">CC_GREEN</p>
                        <p class="cmyk"><strong>CMYK</strong>C53 / M0 / Y10 / K0</p>
                        <p class="rgb"><strong>RGB</strong>R132 / G196 / B64</p>
                    </li>
                    <li class="item_system">
                        <p class="tit">CC_GRAY</p>
                        <p class="cmyk"><strong>CMYK</strong>C3 / M2 / Y0 / K65</p>
                        <p class="rgb"><strong>RGB</strong>R87 / G88 / B90</p>
                    </li>
                </ul>
            </div>
            <div class="official">
                <h3 class="tit_official">공식 이미지</h3>
                <ul class="list_official">
                    <li class="item_official">
                        <div class="tit">Basic</div>
                        <img src="" data-images-path="/image/logo/ceoclinic_basic.svg" alt="">
                    </li>
                    <li class="item_official">
                        <div class="tit">Character</div>
                        <img src="" data-images-path="/image/logo/ceoclinic_extension.svg" alt="">
                    </li>
                </ul>
                <p class="desc_official">시그니쳐는 브랜드명과 사명의 조합으로 이루어진 형태를 기본으로 사용합니다. 어플리케이션에 따라 아래와 같이 확장 형태의 사용이 가능합니다.<br><br> 또한 텍스트(문자)로의 CEO클리닉 브랜드명 사용시, ‘CEO클리닉 피플라이프’(전체 브랜드명) 혹은 ‘CEO클리닉’(브랜드명 단독 사용)으로 사용가능하며, ‘피플라이프 CEO클리닉’으로의 사용을 금합니다. </p>
            </div>
        </div>
    </section>
    <!-- //CI -->
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/brand/ceo/'
        }
    },
}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/brand/ceo.scss';
</style>
