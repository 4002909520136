<template>
<!-- main -->
<main id="container">
	<section id="privacy">
		<div class="inner">
			<div class="titleArea">
				<strong>피플라이프 개인정보 처리방침 V<i class="version"></i></strong>
			</div>
			<div class="frm"></div>
			<div class="tail">
				<div class="select_wrap">
					<u class="select_arrow arrow_css"></u>
					<select>
						<option data-value="version" data-html="text" value="">이전 개인정보 처리방침 보기</option>
					</select>
				</div>
			</div>
		</div>
	</section>
</main>
<!-- /main -->
</template>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■a
        */});// DOCUMENT READY...
    }
}
</script>



<style lang="scss" scoped>

main {margin-top: -5vw;}

.titleArea {text-align: center; font-size: 22px;}
.titleArea::after {content: ""; width: 30px; height: 0; border-top: 2px solid #000; display: block; margin: 15px auto;}

.policy {margin: 0; line-height: 1.6; font-size: 14px; text-align: left;}
.policy *:not(table) {display: inline-block; position: relative;}
.policy :empty {display: block; width: 1px; height: 1em !important; visibility: hidden;}
.policy table {margin: 1em 0; display: table !important;}
.policy table tbody {display: table-row-group !important;}
.policy table colgroup {display: table-column-group !important;}
.policy table colgroup col {display: table-column !important;}
.policy table tr {display: table-row !important;}
.policy table tr th,
.policy table tr td {display: table-cell; padding: 0.6em; font-size: 0.8em; border: 1px solid #ddd; vertical-align: middle; word-break: break-all;}
.policy br+br{line-height: 1;}
.policy h4 {display: block; font-size: 1.2em; font-weight: 500;}
.policy hr {display: block; margin-top: 1em; width: 100% !important; border-top: 1px solid #ddd; visibility: visible !important;}
.policy p {display: block; margin-left: 10px;}
.policy a,
.policy b {color: #0085d0; font-weight: normal;}
.policy em {display: block; color: #666; font-size: 0.85em;}
.policy em q {font-size: 1.2em;}
.policy em q::before,.policy em q::after {content: none; font-size: 1.112em;}
.policy i {position: absolute; top: 0; left: 0; margin-right: 0.3em; width: 1.2em; text-align: right; font-style: inherit;}
.policy i+span {padding-left: 1.5em;}
.policy li {display: block;}
.policy li ul {padding-left: 1.5em;}
.policy dl {display: block;}
.policy dl dd i {position: absolute; top: 0; left: 0; margin-right: 0.3em; width: 1.2em; text-align: right;}
.policy dl dd i+span {padding-left: 1.5em;}
.policy ul {display: block;}
.policy ol {display: block;}
.policy ol li i {position: absolute; top: 0; left: 0; margin-right: 0.3em; width: 1.2em; text-align: right;}
.policy ol li i+span {padding-left: 1.5em;}

#wrap[data-target-device="mo"] {padding-top: 12vw!important; padding-top: 12vw !important;}
#wrap[data-target-device="mo"] ~ .layer_wrap[id*=policy] {width: 100%;}
#wrap[data-target-device="mo"] ~ .layer_wrap[id*=policy] .layer {width: 100%; height: 100%;}
#wrap[data-target-device="mo"] ~ * .policy {font-size: 3.2vw;}

.frm {margin-bottom:30px;border: 1px solid #ddd;}
.frm .cont {padding: 1em;}
.frm .cont li {overflow: hidden; position: relative; height: 50%;}
.frm .cont li .tit {overflow: hidden; padding-top: 1em;}
.frm .cont li .tit h4 {float: left; font-weight: 700; font-size: 17px;}
.frm .cont li .tit h4 b {color: #f00;}
.frm .cont li .tit .agree_area {float: right;}
.frm .cont li .tit .agree_area button {float: left; position: relative; padding-left: 1.7em; background-color: #fff;}
.frm .cont li .tit .agree_area button+button {margin-left: 0.8em;}
.frm .cont li .tit .agree_area button::before {content: ""; position: absolute; top: 50%; left: 0; transform: translateY(-50%); padding: 0.2em; width: 1.2em; height: 1.2em; border-radius: 50%; border: 0.05em solid #ddd; background-clip: content-box; pointer-events: none;}
.frm .cont li .tit .agree_area button.selected::before {background-color: #0085d0; border-color: #0085d0;}
.frm .cont li .li_frm {overflow: auto; position: absolute; top: 3.2em; left: 0; right: 0; bottom: 0; border: 1px solid #ddd;}
.frm .cont li .li_frm article {padding: 0.8em; background-color: #f5f5f5;}

.tail {@include clearfix;margin-bottom:30px}
.tail .select_wrap {position: relative; float: left; border: 1px solid #1c263e;}
.tail .select_wrap i {position: absolute; top: 50%; left: auto; right: 0; transform: translate(-50%, -50%); margin-right: 0; width: 1em; height: 1em; background-size: contain; pointer-events: none; visibility: visible;}
.tail .select_wrap select {display: block; padding: 0 2.2em 0 1em; width: 100%; height: 100%; font-size: 0.94em; line-height: 2;  white-space: nowrap;}
</style>
